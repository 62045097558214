import React from "react";

import "./portfolio.scss";

const Portfolio = () => {
  return (
    <div className="skill-section  u-padding-top u-padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="about-title">
              <h1>Portfolio</h1>
            </div>
          </div>

          {/*  Contact info */}
          <div className="col-md-8">
            {/* Col */}
            <div className="section-content">
              <div className="skill-info">
                <h4>My Recent Work</h4>
                <p>
                  Listed below are some of the projects I've worked on including
                  personal projects
                </p>
              </div>

              <div className="portfolio__content">
                <div className="portfolio__row">
                  {/* ----------- */}
                  <div className="col-sm-6">
                    <div className="portfolio__item">
                      <div className="portfolio__item-top"></div>
                      <div className="portfolio__item-title">
                        Be-Hookd Digital
                      </div>

                      <div className="portfolio__item-content">
                        <ul>
                          <li>React</li>
                          <li>Typescript</li>
                          <li>NextJs</li>
                          <li>Talwind CSS</li>
                        </ul>
                      </div>

                      <div className="portfolio__overlay">
                        <div className="portfolio__overlay-desc">
                          Award-winning digital agency for music, entertainment
                          and lifestyle. We build, manage and monetise your
                          digital presence
                        </div>
                        <div className="portfolio__overlay-cont">
                          <a
                            href="https://be-hookd.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            demo
                          </a>
                          {/* <a
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            source
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ----------- */}
                  <div className="col-sm-6">
                    <div className="portfolio__item">
                      <div className="portfolio__item-top"></div>
                      <div className="portfolio__item-title">Be-Measured</div>

                      <div className="portfolio__item-content">
                        <ul>
                          <li>React</li>
                          <li>Typescript</li>
                          <li>Firebase</li>
                          <li>GCP</li>
                        </ul>
                      </div>

                      <div className="portfolio__overlay">
                        <div className="portfolio__overlay-desc">
                          Be-Measured is used to manage artists' social media
                          accounts and also gets daily metrics regarding
                          engagement from their fans
                        </div>
                        <div className="portfolio__overlay-cont">
                          <a
                            href="https://kit.be-hookd.com/public/be-measured/U2FsdGVkX1%2F4n1YYJNFLjf9nkV%2FJqFTON7GsBqPVKSA87ekdDF2cgFlwXlTVTQisQVFojarFde2tmJrO%2BWeeuKnmCu65zx64t247kGdd%2BP0%3D?start=09-01-2022&end=15-01-2022"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            demo
                          </a>
                          {/* <a
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            source
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="portfolio__item">
                      <div className="portfolio__item-top"></div>
                      <div className="portfolio__item-title">Plugaddons</div>

                      <div className="portfolio__item-content">
                        <ul>
                          <li>React</li>
                          <li>CSS</li>
                          <li>Firebase</li>
                        </ul>
                      </div>

                      <div className="portfolio__overlay">
                        <div className="portfolio__overlay-desc">
                          This is a static web page. Inspiration was gotten from
                          uplabs
                        </div>
                        <div className="portfolio__overlay-cont">
                          <a
                            href="https://plug-addons.firebaseapp.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            demo
                          </a>
                          <a
                            href="https://github.com/denomash/plug-Addons-site"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            source
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="portfolio__item">
                      <div className="portfolio__item-top"></div>
                      <div className="portfolio__item-title">Child Care</div>

                      <div className="portfolio__item-content">
                        <ul>
                          <li>Gatsby</li>
                          <li>SASS</li>
                          <li>Netlify</li>
                        </ul>
                      </div>

                      <div className="portfolio__overlay">
                        <div className="portfolio__overlay-desc">
                          This is a static web page. Inspiration was gotten from
                          uplabs
                        </div>
                        <div className="portfolio__overlay-cont">
                          <a
                            href="https://happy-rosalind-b11033.netlify.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            demo
                          </a>
                          <a
                            href="https://github.com/younggeeks/childcare-UII"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            source
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="portfolio__item">
                      <div className="portfolio__item-top"></div>
                      <div className="portfolio__item-title">Burgetify</div>

                      <div className="portfolio__item-content">
                        <ul>
                          <li>React</li>
                          <li>Redux</li>
                          <li>Webpack</li>
                          <li>Firebase</li>
                        </ul>
                      </div>

                      <div className="portfolio__overlay">
                        <div className="portfolio__overlay-desc">
                          This is a budget management application for tracking
                          your expenses
                        </div>
                        <div className="portfolio__overlay-cont">
                          <a
                            href="https://burgetify.herokuapp.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            demo
                          </a>
                          <a
                            href="https://github.com/denomash/budgetify"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            source
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-6">
                    <div className="portfolio__item">
                      <div className="portfolio__item-top"></div>
                      <div className="portfolio__item-title">Fast-food API</div>

                      <div className="portfolio__item-content">
                        <ul>
                          <li>Python</li>
                          <li>Flask</li>
                          <li>Apiary</li>
                          <li>Heroku</li>
                        </ul>
                      </div>

                      <div className="portfolio__overlay">
                        <div className="portfolio__overlay-desc">
                          This is a fast food API that allow customers to order
                          food.
                        </div>
                        <div className="portfolio__overlay-cont">
                          <a
                            href="https://foodapiv2.docs.apiary.io/#"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            demo
                          </a>
                          <a
                            href="https://github.com/denomash/food-api"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            source
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* End Col */}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
